:root{
  --bg-color-1: #111317;
  --bg-color-2: #181A1E;
  --bg-color-3: #282A2E;
  --bg-color-inverse: #ffffff;

  --txt-color-1: #ffffff;
  --txt-color-2: #aaaaaa;
  --txt-color-3: #505256;
  --txt-color-inverse: #111317;

  --border-color: var(--bg-color-3);

  --primary-color: #365EEE;
  --secondary-color: #FF6F50;

  --transition-default: all 0.3s ease;
}

.txt-body{
  font-size: 15px;
  font-weight: 400;
}

.txt-small{
  font-size: 12px;
  font-weight: 400;
  color: var(--txt-color-2);
}

.txt-medium{
  font-size: 16px;
  font-weight: 400;
}

.txt-header{
  font-size: 17px;
  font-weight: 500;
}

.txt-subtitle{
  font-size: 22px;
  font-weight: 600;
}

.txt-title{
  font-size: 34px;
  font-weight: 800;
}

.txt-mega{
  font-size: 64px;
  font-weight: 900;
}

.app{
  height: 100%;
  color: var(--txt-color-1);
  background-color: var(--bg-color-1);
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  color: var(--txt-color-1); 
}

.material-symbols-rounded-filled {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  color: var(--txt-color-1);
}

*:not(.material-symbols-rounded){
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.adm-button-default{
  background-color: var(--bg-color-2);
  border-color: var(--bg-color-2);
  --text-color: var(--txt-color-1);
  font-weight: 600;
}

.adm-button:not(.adm-button-default){
  --color: var(--bg-color-inverse) !important;
  --text-color: var(--txt-color-inverse) !important;
  font-weight: 600;
}


.adm-list-body, .adm-list-item{
  background-color: transparent !important;
  border: 0 !important;
}

.adm-form-item-label{
  color: var(--txt-color-2) !important;
}

.adm-list-item{
  padding-left: 0 !important;
  border: 0 !important;
}

.adm-input-element{
  color: var(--txt-color-1) !important
}

.adm-list-item-content{
  border-top: 0 !important;
  border-bottom: 2px solid var(--border-color) !important;
}

.adm-dialog-footer button{
  color: var(--primary-color);
}

.adm-nav-bar{
  height: 60px;
  flex: 0 0 60px;
  text-transform: uppercase;
}

p{
  line-height: 1.75;
}

.adm-button-warning{
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: white !important;
}