.body{
    height: 100vh;
    background-color: var(--bg-color-1);
    color: var(--txt-color-1);
}

.content{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 12;
    display: flex;
    flex-direction: column;
}

.content.hide{
    opacity: 0;
}

.bottom{
    width: 100%;
    background-color: var(--bg-color-2);
    padding: 16px;
}

.bottom button{
    
}

.details{
    flex: 1 1 100%;
    background-color: var(--bg-color-1);
    opacity: 0;
    transition: all 0.3s ease;
    padding: 24px;
    overflow-y: auto;
}

.details.show{
    opacity: 1;
    transition: all 0.3s ease;
}

.intro{
    flex: 0 0 auto;
    padding: 24px;
}

.intro :global(.txt-mega){
    display: flex;
    max-width: 8ch;
    transition: all 0.3s ease;
}

.intro :global(.txt-title){
    transition: all 0.3s ease;
}

.background{
    height: calc(100vw);
    transform: translateY(50%);
    transition: all 0.3s ease;
}

.background.small{
    transform: translateX(25%);
    transition: all 0.3s ease;
}

.itenary{
    background-color: var(--secondary-color);
    background: url(../../images/rocket_vertical_white.png), radial-gradient(circle at 50% 0, rgba(255,255,255,1) 0%, rgba(255,125,97,1) 20%, rgba(255,79,41,1) 100%);
    background-size: auto 75%, auto;
    background-repeat: no-repeat;
    background-position: center;
    padding: 16px;
    border-radius: 16px;
    display: flex;
}

.doublecolumn{
    display: flex;
    width: 100%;
}

.doublecolumn > div{
    flex: 1 1 50%;
}

.doublecolumn :global(.txt-title){
    display: flex;
    margin-top: 8px;
}

.itenary .spacer{
    height: 8px;
}

.detail{
    margin: 8px auto;
}

.item{
    display: flex;
    width: 100%;
    padding: 12px 0;
}

.item span{
    white-space: nowrap;
    font-weight: 600;
}

.item :nth-child(2){
    flex: 1 1 100%;
}