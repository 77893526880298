.page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.navbar{
    --height: 60px;
    flex: 0 0 60px;
}

.navbar .logo{
    height: 28px;
}

.content{
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
